var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "xl",
        "ok-variant": "success",
        title: _vm.FormMSG(111, "Vehicule"),
        "ok-title": _vm.FormMSG(1, "Save"),
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: function ($event) {
          $event.preventDefault()
          return _vm.handleSubmitModal.apply(null, arguments)
        },
        cancel: _vm.handleCloseModal,
        hidden: _vm.handleCloseModal,
      },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c(
        "div",
        { staticClass: "form" },
        [
          _c("vehicule-form", {
            ref: "vehiculeFormRef",
            attrs: { "vehicule-data": _vm.vehiculesPayload },
            on: { "vehicule-form:change": _vm.handleVehiculeFormChange },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }