<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		ref="modal"
		v-model="isModalOpen"
		size="sm"
		ok-variant="success"
		:ok-title="FormMSG(1, 'Save')"
		:title="rendModalTitle"
		:cancel-title="FormMSG(2, 'Cancel')"
		@ok="handleSubmitModal"
		@cancel="handleCloseModal"
		@hidden="handleCloseModal"
		modal-class="mui-animation"
		:fade="false"
	>
		<b-form-group :label="FormMSG(11230, 'Firstname')" label-for="supplier-input-firstname">
			<b-form-input id="supplier-input-firstname" v-model="newPassengerPayload.firstName" required type="text" :placeholder="FormMSG(56, 'Firstname')" />
		</b-form-group>
		<b-form-group :label="FormMSG(123, 'Name')" label-for="supplier-input-name">
			<b-form-input id="supplier-input-name" v-model="newPassengerPayload.name" required type="text" :placeholder="FormMSG(89, 'Name')" />
		</b-form-group>
		<b-form-group :label="FormMSG(10, 'Phone')" label-for="supplier-input-phone">
			<b-form-input id="supplier-input-phone" v-model="newPassengerPayload.phone" required type="text" :placeholder="FormMSG(87, 'Phone')" />
		</b-form-group>
	</b-modal>
</template>

<script>
import { isNil, omit } from '~utils';
import { addUpdPassenger } from '@/cruds/transports.crud';
import languageMessages from '@/mixins/languageMessages';

const newPassengerPayloadDefault = { name: null, firstName: null, phone: null };

export default {
	name: 'CrudPassengerModalComponent',
	mixins: [languageMessages],
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		transport: {
			type: Object,
			required: true,
			default: () => {}
		},
		editData: {
			type: Object,
			required: false,
			default: null
		}
	},
	data() {
		return {
			newPassengerPayload: Object.assign({}, newPassengerPayloadDefault),
			isPassengerEdit: false
		};
	},
	computed: {
		/**
		 * @param {Boolean}
		 */
		isEditMode() {
			return !isNil(this.editData);
		},

		isModalOpen: {
			/**
			 * @return {Boolean}
			 */
			get() {
				if (this.value) this.initEditMode();
				return this.value;
			},
			/**
			 * @param {Boolean} status
			 * @return {Emitter}
			 */
			set(status) {
				this.$emit('input', status);
			}
		},

		/**
		 * @return {String}
		 */
		rendModalTitle() {
			return this.isPassengerEdit ? this.FormMSG(1278341, 'Edit passenger') : this.FormMSG(12341, 'Add new passenger');
		}
	},
	methods: {
		initEditMode() {
			if (!isNil(this.editData)) this.isPassengerEdit = true;
			this.newPassengerPayload = {
				...this.newPassengerPayload,
				...this.editData
			};
		},

		handleCloseModal() {
			this.isModalOpen = false;
		},

		async handleSubmitModal() {
			const payload = {
				transportId: this.transport.id,
				passengerId: this.newPassengerPayload.id,
				updatedPassenger: omit(['id'], this.newPassengerPayload)
			};
			const passenger = await addUpdPassenger(payload);
			this.$emit('passenger-added', passenger);
			console.log('handleSubmitModal');
		}
	}
};
</script>
