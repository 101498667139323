var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      ref: "modal",
      attrs: {
        "header-class": "header-class-modal-doc-package",
        size: "sm",
        "ok-variant": "success",
        "ok-title": _vm.FormMSG(1, "Save"),
        title: _vm.rendModalTitle,
        "cancel-title": _vm.FormMSG(2, "Cancel"),
        "modal-class": "mui-animation",
        fade: false,
      },
      on: {
        ok: _vm.handleSubmitModal,
        cancel: _vm.handleCloseModal,
        hidden: _vm.handleCloseModal,
      },
      model: {
        value: _vm.isModalOpen,
        callback: function ($$v) {
          _vm.isModalOpen = $$v
        },
        expression: "isModalOpen",
      },
    },
    [
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(11230, "Firstname"),
            "label-for": "supplier-input-firstname",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "supplier-input-firstname",
              required: "",
              type: "text",
              placeholder: _vm.FormMSG(56, "Firstname"),
            },
            model: {
              value: _vm.newPassengerPayload.firstName,
              callback: function ($$v) {
                _vm.$set(_vm.newPassengerPayload, "firstName", $$v)
              },
              expression: "newPassengerPayload.firstName",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(123, "Name"),
            "label-for": "supplier-input-name",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "supplier-input-name",
              required: "",
              type: "text",
              placeholder: _vm.FormMSG(89, "Name"),
            },
            model: {
              value: _vm.newPassengerPayload.name,
              callback: function ($$v) {
                _vm.$set(_vm.newPassengerPayload, "name", $$v)
              },
              expression: "newPassengerPayload.name",
            },
          }),
        ],
        1
      ),
      _c(
        "b-form-group",
        {
          attrs: {
            label: _vm.FormMSG(10, "Phone"),
            "label-for": "supplier-input-phone",
          },
        },
        [
          _c("b-form-input", {
            attrs: {
              id: "supplier-input-phone",
              required: "",
              type: "text",
              placeholder: _vm.FormMSG(87, "Phone"),
            },
            model: {
              value: _vm.newPassengerPayload.phone,
              callback: function ($$v) {
                _vm.$set(_vm.newPassengerPayload, "phone", $$v)
              },
              expression: "newPassengerPayload.phone",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }