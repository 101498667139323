<template>
	<div class="dynamic-address-field--component-wrapper">
		<b-row>
			<b-col sm="12" :md="disabled ? 12 : 8" :lg="disabled ? 12 : 8" :xl="disabled ? 12 : 8">
				<div class="fs-14 fw-700" v-html="rendFilledAddress" />
			</b-col>
			<b-col v-if="!disabled" sm="12" md="4" lg="4" xl="4" :class="{ 'mt-2': $screen.width < 576 }">
				<b-button block variant="success mr-2" size="sm" :disabled="disabled" @click="openAdresseModal">
					<!-- <span v-if="!checkAddress">{{  }}</span>
              <span v-if="checkAddress">{{ FormMSG(2, 'Edit') }}</span> -->
					<component :is="getLucideIcon(checkAddress ? 'Edit' : 'PlusCircle')" :size="16" />
					<span v-html="checkAddress ? FormMSG(2, 'Edit') : FormMSG(1, 'Add')" />
				</b-button>

				<b-button block v-if="!hideRemoveBtn" variant="danger" size="sm" @click="handleDeleteAddress">
					<component :is="getLucideIcon('Trash2')" :size="16" /> {{ FormMSG(3, 'Remove') }}
				</b-button>
			</b-col>
		</b-row>

		<b-modal
			header-class="header-class-modal-doc-package"
			v-model="isAddressModalOpen"
			:title="rendAddressTitle"
			ref="modal"
			size="xl"
			ok-variant="success"
			:disabled="disabled"
			:ok-title="FormMSG(34223434, 'Save')"
			:cancel-title="FormMSG(9878667, 'Cancel')"
			@ok="handleAddressSubmit"
			@cancel="handleAdressModalCancel"
			@hidden="handleAdressModalCancel"
			modal-class="mui-animation"
			:fade="false"
		>
			<AddressForm :show-clear-address-btn="showClearAddressBtn" :edit-data="editAddressValue" @change="handleAdressChange" />
		</b-modal>
	</div>
</template>

<script>
import mapProps from '@/shared/vuePropsMapper';
import globalMixin from '@/mixins/global.mixin';
import languageMessages from '@/mixins/languageMessages';
import addressHelperMixin from '@/components/LocationService/addressHelper.mixin';
import dynamicAddressItemMixin from '@/components/LocationService/dynamicAddressItem.mixin';

export default {
	name: 'DynamicAddressFieldComponent',
	mixins: [globalMixin, addressHelperMixin, languageMessages, dynamicAddressItemMixin],
	props: mapProps(['hideRemoveBtn', 'disabled', 'showClearAddressBtn'], {
		type: Boolean,
		required: false,
		default: false
	})
};
</script>

<style lang="scss" scoped>
.dynamic-address-field--component-wrapper {
	/*display: flex;*/
	/*justify-content: space-between;*/
	/*align-items: center;*/
}
</style>
